import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ImgLoader from './ImgLoader'

import applyMods from '@/shared/utils/applyMods'

const useStyles = makeStyles({
    root: {
        height: '30px',
        width: '30px',
        maxHeight: '30px',
        maxWidth: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
        '&--w24': {
            height: '24px',
            width: '24px',
            maxHeight: '24px',
            maxWidth: '24px',
        },
        '&--w64': {
            height: '75px',
            width: '75px',
            maxHeight: '75px',
            maxWidth: '75px',
        },
        '&--w48': {
            height: '48px',
            width: '48px',
            maxHeight: '48px',
            maxWidth: '48px',
        },
        '&--w75': {
            height: '75px',
            width: '75px',
            maxHeight: '75px',
            maxWidth: '75px',
        },
    },
})

interface IProps {
    src: string;
    failoverImage?: string;
    className?: string;
    mods?: string;
    alt?: string;
}

export function Img({
    src,
    className,
    failoverImage,
    alt,
    mods
}: IProps): React.ReactElement {
    const classes = useStyles()

    return (
        <div className={`${applyMods(classes.root, mods)}${className ? ` ${className}` : ''}`}>
            <ImgLoader src={src} failoverImage={failoverImage} alt={alt}/>
        </div>
    )
}