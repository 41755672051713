import { IGame } from '@/shared/models/Game';
import { useClub } from 'contexts/club';
import { useEffect, useState } from 'react';
import { getGame } from 'services/games';

export type ILoadedGamesMap = {
    [key in number]?: IGame
}

export type ILoadingGames = {
    [key in number]?: boolean
}

export interface IUseGameLoaderParams {
    initialLoadedGames: ILoadedGamesMap,
    games: IGame[],
    initialGameIndex: number
}

export default function useGameLoader(params: IUseGameLoaderParams) {
    const { games, initialGameIndex } = params
    const [loadedGamesMap, setLoadedGamesMap] = useState<ILoadedGamesMap>(params.initialLoadedGames)
    const [loadingGames, setLoadingGames] = useState<ILoadingGames>({})
    const { prefix } = useClub()
    const [currentIndex, setCurrentIndex] = useState(initialGameIndex)
    const currentGameId = games[currentIndex]?.id

    useEffect(() => {
        if(typeof currentGameId === 'number' && !loadedGamesMap[currentGameId] && !loadingGames[currentGameId]) {
            loadGame(currentGameId)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGameId])

    return {
        currentIndex,
        currentGame: loadedGamesMap[currentGameId],
        onNavigate
    } as const

    async function loadGame(gameId: number) {
        if(!loadingGames[gameId] && !loadedGamesMap[currentGameId]) {
            setLoadingGames(prev => ({...prev, [gameId]: true}))
            const game = await getGame(gameId, { clubPrefix: prefix })
                .catch(() => null)
            setLoadedGamesMap(prev => ({...prev, [gameId]: game}))
            setLoadingGames(prev => ({...prev, [gameId]: false }))
        }
    }

    function onNavigate(direction: 'next' | 'prev') {
        setCurrentIndex(prev => direction === 'next' ? prev + 1 : prev - 1)        
    }
}