import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Card from '@/shared/components/Card';
import IconButton from '@/shared/components/Button/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Hidden from '@material-ui/core/Hidden'
import { Headline3 } from '@/shared/components/Typography';

type ITeamGamesPlaceholderProps = {
    title: string
}
export default function TeamGamesPlaceholder(props: ITeamGamesPlaceholderProps) {
    return (
        <Box>
            <Box pb={4} display='flex' textAlign='center' justifyContent='center'>
                <Headline3>{props.title}</Headline3>
            </Box>
            <Box display='flex' alignItems='center'>
                <Hidden smDown>
                    <Box px={1}>
                        <IconButton disabled><ArrowBackIcon /></IconButton>
                    </Box>
                </Hidden>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <Box height={120}>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Card>
                            <Box height={120}>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Box px={1}>
                        <IconButton disabled><ArrowForwardIcon /></IconButton>
                    </Box>
                </Hidden>
            </Box>
            <Hidden mdUp>
                <Box pt={4}>
                    <Grid container>
                        <Grid item xs={6} container alignItems='center' justifyContent='flex-start'>
                            <IconButton disabled><ArrowBackIcon /></IconButton>
                        </Grid>
                        <Grid item xs={6} container alignItems='center' justifyContent='flex-end'>
                            <IconButton disabled><ArrowForwardIcon /></IconButton>
                        </Grid>
                    </Grid>
                </Box>
            </Hidden>
        </Box>
    )
}