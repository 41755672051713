import Box from '@material-ui/core/Box';
import { buildUrlFromParams, ISectionComponentProps } from 'components/Sections/utils';
import Grid from '@material-ui/core/Grid';
import ValidatedSelectInput from '@/shared/components/Form/Material/ValidatedSelectInput'
import GameCard from '../GameCard';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { getGroupGames, getGroupLeagueGames } from 'services/games';
import Loader from '@/shared/components/Loader/Loader';
import { getLeagueSeasonsWithGames, getSeasonsByType } from 'services/seasons';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import ResultsNavigation from 'components/Sections/ResultsNavigation';
import GamedayList from '../GamedayList';
import { unique } from '@/shared/utils/unique';
import { gameSorter } from '@/shared/utils/helpers';
import { IGame } from '@/shared/models/Game';
import { ISeason } from '@/shared/models/Season';
import { useClub } from 'contexts/club';
import { Headline3 } from '@/shared/components/Typography';
import TablePlaceholder from 'components/Sections/Placeholders/TablePlaceholder';
import { FRIENDLY_GAMES_ID } from '../const';

const ALL_LEAGUES_ID = 0

export default function GamesDefaultLayout(props: ISectionComponentProps) {
    const { data } = props
    const { games, categories, group: selectedGroup, league, season, seasons, leagues, } = data
    const router = useRouter()
    const { slug } = router.query
    const { prefix, pathPrefix } = useClub()
    const [currentGames, setCurrentStats] = useState(games)
    const [currentGameday, setCurrentGameday] = useState(-1)
    const [selectedSeason, setSelectedSeason] = useState(season?.id || 0)
    const [currentLeague, setCurrentLeague] = useState(league?.id || 0)
    const [seasonsList, setSeasonsList] = useState(seasons)
    const [isSeasonsLoading, setIsSeasonsLoading] = useState(false)
    const gamesLoaded = useRef(false)
    const [isGamesLoading, setIsGamesLoading] = useState(false)
    const seasonsLoaded = useRef(false)
    const intl = useIntl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const selectedLeague = useMemo(() => leagues.find(e => e.id === currentLeague),[currentLeague])
    // fetch stats if season or current page or current league changed
    useEffect(() => {
        if(gamesLoaded.current && !isSeasonsLoading && selectedSeason) {
            fetchData({ season: selectedSeason, league: currentLeague });
        }
        const basePrefix = props.isPreview ? `${pathPrefix}/preview` : pathPrefix
        const basePath = `/${[basePrefix, slug[0]].join('/')}`
        const url = buildUrlFromParams(basePath, {
            groups: selectedGroup?.id,
            leagues: currentLeague,
            seasons: selectedSeason
        }, ['leagues', 'groups', 'seasons'], { })
        router.replace(url, undefined, { shallow: true })
        gamesLoaded.current = true
        setCurrentGameday(-1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSeason, currentLeague])

    const gamedays = useMemo(() => {
        if(!selectedGroup) {
            return unique(currentGames.map(e => e.game_day).filter(Boolean)).sort((a, b) => a-b)
        }
        return []
    }, [currentGames, selectedGroup])

    // fetch seasons if league changed
    useEffect(() => {
        if(seasonsLoaded.current) {
            fetchSeasons({ league: currentLeague })
        }
        seasonsLoaded.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLeague])

    useEffect(() => {
        if (currentLeague && leagues?.length) {
            const selectedLeague = leagues.find(league => league.id === currentLeague)
            if (!selectedLeague && currentLeague !== FRIENDLY_GAMES_ID) setCurrentLeague(leagues[0].id)
        }
    }, [leagues, currentLeague])

    const filteredCurrentGames = useMemo(() => {
        if(currentGameday) {
            return currentGames.filter(e => e.game_day === currentGameday)
        }
        return currentGames
    }, [currentGames, currentGameday])

    const options = [{ label: intl.formatMessage(sectionMessages['section.results.all_leagues']), value: ALL_LEAGUES_ID }]
        .concat(leagues?.map(e => ({ label: e.name, value: e.id })))
        .concat([{ label: intl.formatMessage(sectionMessages['section.games.friendly_games']), value: FRIENDLY_GAMES_ID }])


    useEffect(() => {
        let timeout
        if (currentGameday === -1) {
            timeout = setTimeout(() => setCurrentGameday(0), 1000)
        }
        return () => {
            clearTimeout(timeout)
        }
    })

    return (
        <Box>
            <ResultsNavigation categories={categories} initialIndex={1} group={selectedGroup} league={league} seasonId={selectedSeason} />
            <Box pb={4}>
                <Grid container spacing={4}>
                    {selectedGroup && (
                        <Grid item xs={12} sm={5} md={5}>
                            <ValidatedSelectInput
                                id='league'
                                value={currentLeague}
                                name='league'
                                label={intl.formatMessage(sectionMessages['section.label.league'])}
                                options={options}
                                onChange={(event) => {
                                    setCurrentLeague(Number(event.target.value))
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={5} md={5}>
                        <ValidatedSelectInput
                            id='season'
                            value={selectedSeason}
                            name='season'
                            label={intl.formatMessage(sectionMessages['section.label.season'])}
                            options={seasonsList.map(e => ({ label: e.name, value: e.id }))}
                            onChange={(event) => setSelectedSeason(Number(event.target.value))}
                        />
                    </Grid>
                    {!selectedGroup && (
                        <Grid item xs={12} sm={5} md={5}>
                            <ValidatedSelectInput
                                id='gameday'
                                value={currentGameday}
                                name='gameday'
                                label={intl.formatMessage(sectionMessages['section.label.gameday'])}
                                options={[
                                    {
                                        label: intl.formatMessage(sectionMessages['section.label.all']),
                                        value: 0
                                    },
                                    ...gamedays.map(e => ({
                                        label: `${intl.formatMessage(sectionMessages['section.label.gameday'])} ${e}`,
                                        value: e,
                                    }))
                                ]}
                                onChange={(event) => {
                                    setCurrentGameday(Number(event.target.value))
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box pb={4}>
                {isGamesLoading || currentGameday === -1 && (
                    <Loader/>
                )}
                {!isGamesLoading && selectedGroup && filteredCurrentGames.map(e => (
                    <GameCard selectedLeague={selectedLeague} showLeague key={e.id} game={e} categories={categories}/>
                ))}
                {!isGamesLoading && !selectedGroup && (
                    <GamedayList gamedays={gamedays} games={filteredCurrentGames} categories={categories} />
                )}
                {filteredCurrentGames.length === 0 && !isGamesLoading && currentGameday !== -1 && (
                    <>
                        <Box pb={4}>
                            <Headline3><b>{intl.formatMessage(sectionMessages['table.empty'])}</b></Headline3>
                        </Box>
                        <Box pb={4}>
                            <TablePlaceholder rows={4} />
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
    

    async function fetchData({ season, league }: { season: number, league: number}) {
        setIsGamesLoading(true)
        let result: IGame[] = []
        if(league && league !== FRIENDLY_GAMES_ID) {
            result = await getGroupLeagueGames({ leagueId: league, groupId: selectedGroup?.id, seasonId: season, clubPrefix: prefix as string })
            .catch(() => [])
                .finally(() => setIsGamesLoading(false))
        } else {
            result = await getGroupGames({
                groupId: selectedGroup?.id,
                seasonId: season,
                clubPrefix: prefix,
                isFriendly: league === FRIENDLY_GAMES_ID
            })
                .catch(() => [])
                .finally(() => setIsGamesLoading(false))
        }
        result.sort(gameSorter)
        setCurrentStats(result)
    }

    async function fetchSeasons({ league }: { league: number }) {
        setIsSeasonsLoading(true)
        let result: ISeason[] = []
        if(league && league !== FRIENDLY_GAMES_ID) {
            result = await getLeagueSeasonsWithGames({ clubPrefix: prefix as string, leagueId: league, groupId: selectedGroup?.id }).catch(() => ([]))
        } else if(selectedGroup) {
            result = await getSeasonsByType({ clubPrefix: prefix as string, seasonTypeId: selectedGroup?.season_type_id })
        }
        setSeasonsList(result)
        const selectedSeasonExist = result.find(e => e.id === selectedSeason)
        const currentSeasonExist = result.find(e => e.current)
        setIsSeasonsLoading(false)
        if(currentSeasonExist || selectedSeasonExist) {
            setSelectedSeason(currentSeasonExist ? currentSeasonExist?.id : selectedSeasonExist?.id)
            return
        }
        setSelectedSeason(result[0]?.id || 0)
        setCurrentStats([])
    }
}