import Tabs from '@/shared/components/Tabs/Tabs';
import { WebsitePageType } from '@/shared/models/Website';
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useSystemPagePath } from 'utils/hooks';
import { Img } from '@/shared/components/Img/Img';
import { Headline2 } from '@/shared/components/Typography';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import { IGroup } from '@/shared/models/Group';
import { ILeague } from '@/shared/models/League';
import { useIntl } from 'react-intl';
import { sectionMessages } from '../messages';
import { ICategory } from '@/shared/models/Category';
import { generateTagLabel } from '@/shared/utils/generateTeamName';
import { useTranslations } from 'contexts/translations';

type IResultsNavigationProps = {
    seasonId: number
    group?: IGroup
    league: ILeague
    initialIndex: number,
    categories: ICategory[]
}
export default function ResultsNavigation(props: IResultsNavigationProps) {
    const { seasonId, group, initialIndex, league, categories } = props
    const queryParams = { leagues: league?.id, groups: group?.id, seasons: seasonId }
    const statsLink = useSystemPagePath(WebsitePageType.STATISTICS, queryParams) 
    const rosterLink = useSystemPagePath(WebsitePageType.ROSTER, queryParams) 
    const standingsLink = useSystemPagePath(WebsitePageType.STANDINGS, queryParams) 
    const resultsLink = useSystemPagePath(WebsitePageType.RESULTS, queryParams)
    const displayPic = (group ? group?.pic : league?.picture) ?? DEFAULT_TEAM_LOGO
    const { translations: { messages } } = useTranslations()
    const intl = useIntl()
    const isTabsVisible = group ? group?.is_team : true
    const displayImage = group?.is_team === true
    const hideStandingsTab = league?.competition_phases?.every(phase => phase.phase.type === 'knockout')

    return (
        <Box py={4}>
            {(group || league) && (
                <Box pb={4} display='flex' alignItems='center'>
                    {displayImage && <Img mods='w64' src={displayPic} failoverImage={DEFAULT_TEAM_LOGO}/>}
                    <Box p={displayImage ? 2 : 0}>
                        <Headline2>{group ? generateTagLabel(group, null, categories, {
                            useTranslationsInsteadOfIntl: true,
                            translations: messages
                        }) : league?.name}</Headline2>
                    </Box>
                </Box>
            )}
            {isTabsVisible && (
                <Grid container>
                    <Grid item md={6} xs={12}>
                        <Tabs selectedIndex={group ? initialIndex : initialIndex - 1} tabs={[
                            group && {
                                title: intl.formatMessage(sectionMessages['navigation.tabs.roster']),
                                link: rosterLink,
                            },
                            {
                                title: intl.formatMessage(sectionMessages['navigation.tabs.results']),
                                link: resultsLink,
                            },
                            !hideStandingsTab && {
                                title: intl.formatMessage(sectionMessages['navigation.tabs.standings']),
                                link: standingsLink,
                            },
                            {
                                title: intl.formatMessage(sectionMessages['navigation.tabs.statistics']),
                                link: statsLink,
                            },
                        ].filter(Boolean)}/>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}