import { ICategory } from '@/shared/models/Category';
import { IGame } from '@/shared/models/Game';
import { groupBy } from '@/shared/utils/lodashFunc';
import { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import GameCard from './GameCard';
import { Headline3 } from '@/shared/components/Typography';
import { useIntl } from 'react-intl';
import { sectionMessages } from '../messages';

interface IGamedayListProps {
  games: IGame[]
  categories: ICategory[],
  gamedays: number[]
}

export default function GamedayList(props: IGamedayListProps) {
    const { games, categories, gamedays } = props
    const groupedGames = useMemo(() => groupBy(games, 'game_day'), [games])
    const intl = useIntl()
    return (
        <Box pb={4}>
            {gamedays.map((gameday) => renderGames(groupedGames[gameday], gameday))}
            {groupedGames['undefined'] && renderGames(groupedGames['undefined'])}
        </Box>
    )

    function renderGames(list: IGame[], gameday?: number) {
        if(!Boolean(list?.length)) {
            return null
        }
        return (
            <Box key={gameday || 'default'} pb={4}>
                <Box pb={4}>
                    {gameday && (
                        <Headline3>{`${intl.formatMessage(sectionMessages['section.label.gameday'])} ${gameday}`}</Headline3>
                    )}
                </Box>
                {list.map(game => (
                    <GameCard game={game} key={game.id} categories={categories}/>
                ))}
            </Box>
        )
    }
}