import { ICountry } from '../models/Country'
import { IEvent } from '../models/Event'
import { IGame } from '../models/Game'
import { IMessages } from '../models/Messages'
import { capitalize } from './capitalize'

interface IDescriptionProps {
    venueAddress: string;
    venueAddressName: string;
    venueCity: string;
    countryName: string;
}

export const generateDescription = (descriptionProps: IDescriptionProps): string => {
    const {venueAddress, venueAddressName, venueCity, countryName} = descriptionProps

    return [
        venueAddress === venueAddressName ? '' : venueAddressName,
        venueAddress,
        venueCity,
        countryName,
    ].filter(item => item && item.length).join(', ')
}

export const getVenueCountry = (countryShortName = '', countries = []): { id: number } => {
    const normalizedCountryShortName = countryShortName === 'gb' ? 'uk' : countryShortName
    const country: ICountry = countries.find(({ name }) => name === normalizedCountryShortName)
    const venueCountry = {
        id: country?.id || null
    }

    if (!normalizedCountryShortName || !venueCountry.id) {
        return {
            id: -1
        }
    }

    return venueCountry
}

export function getVenueDescription(eventInfo: IEvent | IGame, countriesInfo: ICountry[], translations: IMessages): string {
    const countryId = eventInfo.venue_country ? eventInfo.venue_country.id : 0
    const countryObj = countriesInfo.find(({ id }) => id === countryId)

    const formattedCountryName = countryObj && countryObj.name && translations[countryObj.name] ? capitalize(translations[countryObj.name]) : ''
    return generateDescription({
        venueAddress: eventInfo.venue_address,
        venueAddressName: eventInfo.venue_address_name,
        venueCity: eventInfo.venue_city,
        countryName: formattedCountryName
    })
}

