import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { Slug } from '@/shared/components/Slug'
import { Headline3, Paragraph2, Headline2 } from '@/shared/components/Typography'
import { Separator } from '@/shared/components/Separator/Separator';
import IconButton from '@/shared/components/Button/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SecondaryButton from '@/shared/components/Button/SecondaryButton';
import Card from '@/shared/components/Card';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import { Img } from '@/shared/components/Img/Img'
import { IGamesSectionData } from '../types'
import { useMemo } from 'react'
import { IGroup } from '@/shared/models/Group'
import { checkFutureGame, displayGameScore, displayLiveStreamButton, displayReplayButton, hasScore } from '@/shared/utils/helpers'
import { getVenueDescription } from '@/shared/utils/addressFunc'
import { useTranslations } from 'contexts/translations'
import { generateTagLabel } from '@/shared/utils/generateTeamName'
import { useIntl } from 'react-intl'
import { sectionMessages } from 'components/Sections/messages'
import moment from 'moment'
import { WebsitePageType } from '@/shared/models/Website'
import { useSystemPagePath } from 'utils/hooks'
import TeamGamesPlaceholder from '../TeamGamesPlaceholder'
import { useGameCardStyles } from '../styles'
import useGameLoader from '../hooks/useGameLoader'
import Loader from '@/shared/components/Loader/Loader'
import { AdManager } from '@/shared/components/AdManager/AdManager'
import { useClub } from 'contexts/club'
import LiveStreamButton from '@/shared/components/Button/LiveStreamButton'

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`

export default function TeamGames(props: IGamesSectionData) {
    const { club, modes } = useClub()
    const { translations: { messages } } = useTranslations()
    const { games, countries, categories, group, closestGameIndex, initialLoadedGames, section } = props
    const { currentGame, currentIndex, onNavigate } = useGameLoader({ initialLoadedGames, initialGameIndex: closestGameIndex, games })
    const isCurrentGameLoading = Boolean(!currentGame)
    const intl = useIntl()
    const isFutureGame = useMemo(() => currentGame ? checkFutureGame(currentGame) : false, [currentGame])
    const gameLink = `${useSystemPagePath(WebsitePageType.GAME)}/${currentGame?.id}`
    const gameTime = useMemo(getGameTime, [currentGame])
    const isEmpty = !games || games.length === 0;
    const { simpleDateFormatter } = useTranslations()
    const classes = useGameCardStyles({ hasLeagueButton: false })
    const hasGameScore = hasScore(currentGame)
    const showLiveStreamButton = displayLiveStreamButton(currentGame)
    const showReplayButton = displayReplayButton(currentGame)

    if(isEmpty || !group) {
        return (
            <Box key={1}>
                <TeamGamesPlaceholder title={intl.formatMessage(sectionMessages['games.empty'])}/>
            </Box>
        )
    }

    if(isCurrentGameLoading) {
        return (
            <Box key={2} minHeight={303} display='flex' justifyContent='center' alignItems='center'>
                <Loader/>
            </Box>
        )
    }

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='gamepreview'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={modes.find(m => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    return (
        <Box key={3} minHeight={303}>
            <Hidden mdUp>
                <Box py={4} display='flex' flexDirection='column' alignItems='center' textAlign='center'>
                    {renderGameDetails()}
                </Box>
                <Box pb={4}>
                    <Separator/>
                </Box>
            </Hidden>
            <Box pb={4} display='flex' alignItems='center'>
                <Hidden smDown>
                    <Box px={1}>
                        {renderBackBtn()}
                    </Box>
                </Hidden>
                <Grid container>
                    <Hidden smDown>
                        <Grid item md={3}>
                            <Box py={4} display='flex' flexDirection='column' alignItems='flex-end' textAlign='center'>
                                {renderGameDetails()}
                                {renderViewEventBtn()}
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item container sm={12} md={9} alignItems='flex-end' className={classes.teamWrapper}>
                        <Box className={classes.teamScoreContainer}>
                            <Grid item xs={3} sm={4} className={(isFutureGame || currentGame.cancelled) && classes.teamPadding}>
                                {renderTeam(currentGame?.team1)}
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <Box height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' textAlign='center'>
                                    {isFutureGame && !currentGame.cancelled && (
                                        <Paragraph2>{intl.formatMessage(sectionMessages['games.kick_off'])}</Paragraph2>
                                    )}
                                    {currentGame.cancelled && (
                                        <Paragraph2>{intl.formatMessage(sectionMessages['games.cancelled'])}</Paragraph2>
                                    )}
                                    <Card>
                                        <Box p={3} className={currentGame.cancelled && hasGameScore && classes.cancelledText}>
                                            <Headline2>{isFutureGame ? gameTime : displayGameScore(currentGame)}</Headline2>
                                        </Box>
                                    </Card>
                                    {(showLiveStreamButton || showReplayButton) && (
                                        <Hidden mdUp>
                                            <Box pt={4}>
                                                <LiveStreamButton streamLink={currentGame.stream_link} showLiveStream={showLiveStreamButton} />
                                            </Box>
                                        </Hidden>
                                    )}
                                </Box>
                            </Grid>
                            <Grid item xs={3} sm={4} className={(isFutureGame || currentGame.cancelled) && classes.teamPadding}>
                                {renderTeam(currentGame?.team2)}
                            </Grid>
                        </Box>
                        <Hidden smDown>
                            {renderAdManager(currentGame.id)}
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Box px={1}>
                        {renderForwardBtn()}
                    </Box>
                </Hidden>
            </Box>
            <Hidden mdUp>
                <Box pb={4}>
                    <Grid container>
                        <Grid item xs={2} container alignItems='center' justifyContent='flex-start'>
                            {renderBackBtn()}
                        </Grid>
                        <Grid item xs={8} container alignItems='center' justifyContent='center'>
                            {renderViewEventBtn()}
                        </Grid>
                        <Grid item xs={2} container alignItems='center' justifyContent='flex-end'>
                            {renderForwardBtn()}
                        </Grid>
                        <Box pt={4} width='100%'>
                            {renderAdManager(currentGame.id)}
                        </Box>
                    </Grid>
                </Box>
            </Hidden>
        </Box>
    )

    function renderTeam(team: IGroup) {
        return (
            <Box height="100%" textAlign='center' justifyContent='center' display='flex' flexDirection='column' alignItems='center'>
                <Img
                    className={classes.teamImage}
                    src={team.pic || DEFAULT_TEAM_LOGO}
                    failoverImage={DEFAULT_TEAM_LOGO}
                />
                <Paragraph2>
                    <b>
                        {generateTagLabel(team, null, categories, {
                            useTranslationsInsteadOfIntl: true,
                            translations: messages
                        })}
                    </b>
                </Paragraph2>
            </Box>
        )
    }

    function renderForwardBtn() {
        return  (
            <IconButton disabled={currentIndex === (games.length - 1)} onClick={() => onNavigate('next')}><ArrowForwardIcon /></IconButton>
        )
    }

    function renderBackBtn() {
        return (
            <IconButton disabled={currentIndex === 0}  onClick={() => onNavigate('prev')}><ArrowBackIcon /></IconButton>
        )
    }

    function renderViewEventBtn() {
        return (
            <SecondaryButton applyBackgroundColor className={classes.viewEvent} href={gameLink}>
                {intl.formatMessage(sectionMessages['calendar.more_information'])}
            </SecondaryButton>
        )
    }

    function getGameTime() {
        const momentDate = moment(currentGame?.start_date)
        return `${momentDate.format('HH')}h${momentDate.format('mm')}`
    }

    function renderGameDetails() {
        return (
            <>
                <Box py={4}>
                    <Slug imgSrc={`${iconPath}/websites/Match.svg`} text={<Paragraph2 isAccent><b>{currentGame?.competition?.name ?? intl.formatMessage(sectionMessages['games.friendly_game'])}</b></Paragraph2>}/>
                </Box>
                <Headline3>{simpleDateFormatter(currentGame?.start_date, 'DD.MM.YYYY')}</Headline3>
                {(showLiveStreamButton || showReplayButton) && (
                    <Hidden smDown>
                        <LiveStreamButton streamLink={currentGame.stream_link} showLiveStream={showLiveStreamButton} />
                    </Hidden>
                )}
                <Paragraph2 className={classes.venueDesctipntion}>{getVenueDescription(currentGame, countries, messages)}</Paragraph2>
            </>
        )
    }
}