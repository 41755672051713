import { SectionLayoutType } from '@/shared/models/Section';
import { isEmpty } from '@/shared/utils/lodashFunc';
import { useTranslations } from 'contexts/translations';
import { useEffect } from 'react';
import { ISectionComponentProps } from '../utils';
import GamesDefaultLayout from './Layout/GamesDefaultLayout';
import LeagueGames from './Layout/LeagueGames';
import TeamGames from './Layout/TeamGames';
import FlfGames from './Layout/FlfGames';


export default function GamesContainer(props: ISectionComponentProps) {
    const { placeholders } = props.data

    const {extendTranslations } = useTranslations()

    useEffect(() => {
        if(!isEmpty(placeholders))
            extendTranslations(placeholders)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [placeholders])

    if(props.section.layout === SectionLayoutType.DEFAULT) {
        return <GamesDefaultLayout {...props}/>
    }

    if(props.section.layout === SectionLayoutType.TEAM) {
        return <TeamGames {...props.data}/>
    }

    if(props.section.layout === SectionLayoutType.FLF) {
        return (
            <FlfGames {...props.data}/>
        )
    }
    return (
        <LeagueGames {...props}/>
    )
}